document.addEventListener(
    'DOMContentLoaded',
    () => {
        const modalContainers = document.querySelectorAll('.modal');
        const triggers = document.querySelectorAll('.modal-trigger');

        triggers.forEach((trigger) => {
            trigger.addEventListener(
                'click',
                (e) => {
                    const modal = document.querySelector(e.target.getAttribute('data-modal'));

                    if (modal) {
                        modal.classList.remove('fade-out');
                        modal.classList.add('fade-in');
                    }
                    document.body.classList.add('modal__open');

                    e.preventDefault();
                },
                false,
            );
        });

        modalContainers.forEach((modalContainer) => {
            const handleClick = (e) => {
                modalContainer.classList.remove('fade-in');
                modalContainer.classList.add('fade-out');
                document.body.classList.remove('modal__open');
                e.preventDefault();
            };

            modalContainer.querySelector('.modal__overlay').addEventListener('click', handleClick, false);
            modalContainer.querySelector('.modal__close').addEventListener('click', handleClick, false);
        });
    },
    false,
);
