if (typeof window !== 'undefined' && 'serviceWorker' in navigator) {
    window.navigator.serviceWorker.getRegistrations().then((registrations) => {
        registrations.forEach((r) => {
            if (
                r.scope.includes(`${window.location.origin}${window.location.pathname}`) ||
                r.scope.includes(`${window.location.origin}/${window.location.pathname}`)
            ) {
                r.unregister();
            }
        });
    });
}
