<template>
  <div class="container_content_group">
    <div v-if="searchOption" class="container_search_option">
      <div class="container_search_content">
        <img :src="SearchIcon" loading="lazy" alt="Magnification icon" />
        <input
          class="container_search_input"
          v-model="search_term"
          :placeholder="translate('home.search_city_placeholder')"
        />
      </div>
    </div>
    <div class="container_content_bottom">
      <div :class="searchOption ? 'container_content_options search' : 'container_content_options'">
        <a
          :href="option.url"
          v-for="(option, index) in options.filter((o) => o.name.toLowerCase().includes(search_term.toLowerCase()))"
          class="container_content_line"
          :class="index !== options.length - 1 ? 'container_content_line_separator' : ''"
          :key="option.id"
          @click="trackLanguageChange(option.hasOwnProperty('code'))"
        >
          <span
            v-if="option.hasOwnProperty('code')"
            :class="'flag_icon--' + (option.code === 'en_US' ? 'en' : option.code)"
          />
          <span class="container-content_category" :class="isSeleceted(option.name) ? 'selected' : ''">
            {{ option.name }}
          </span>
        </a>
      </div>
    </div>
  </div>
</template>

<script>
import { googleTagManager, tealium } from '../../modules/tracking';
import SearchIcon from '../../../img/navigation/search_32.svg';
import TranslateMixin from '../TranslateMixin.vue';
export default {
  data() {
    return {
      SearchIcon,
      search_term: '',
    };
  },

  methods: {
    isSeleceted(name) {
      return this.selectedOption === name;
    },
    trackLanguageChange(isLanguage) {
      if (!isLanguage) return;
      tealium.hasChangedLanguage();
      googleTagManager.trackEventWithName('hasChangedLanguage');
    },
  },

  props: {
    options: {
      type: Array,
      default: null,
    },
    searchOption: {
      type: Boolean,
      default: false,
    },
    language: {
      type: Object,
      default: null,
    },
    selectedOption: {
      type: String,
      default: null,
    },
  },
  mixins: [TranslateMixin],
};
</script>
