document.addEventListener(
    'DOMContentLoaded',
    () => {
        const videoContainers = document.querySelectorAll('.videobox');

        videoContainers.forEach((videoContainer) => {
            videoContainer.querySelector('.video__play').addEventListener(
                'click',
                (e) => {
                    const iframe = videoContainer.querySelector('.videobox__overlay__container iframe');
                    iframe.setAttribute('src', iframe.getAttribute('data-url'));
                    const videoOverlay = videoContainer.querySelector('.videobox__overlay');
                    videoOverlay.classList.remove('fade-out');
                    videoOverlay.classList.add('fade-in');
                    document.body.classList.add('modal__open');
                    e.preventDefault();
                },
                false,
            );

            const closeVideo = (e) => {
                const videoOverlay = videoContainer.querySelector('.videobox__overlay');
                videoOverlay.classList.remove('fade-in');
                videoOverlay.classList.add('fade-out');
                document.body.classList.remove('modal__open');
                const iframe = videoContainer.querySelector('.videobox__overlay__container iframe');
                iframe.setAttribute('src', '');
                e.preventDefault();
            };

            videoContainer.querySelector('.videobox__overlay__content').addEventListener('click', closeVideo, false);
            videoContainer.querySelector('.videobox__overlay__content').addEventListener(
                'click',
                () => {
                    videoContainer.scrollIntoView();
                },
                false,
            );
            videoContainer.querySelector('.videobox__overlay__close').addEventListener('click', closeVideo, false);
            videoContainer.querySelector('.videobox__overlay__close').addEventListener(
                'click',
                () => {
                    videoContainer.scrollIntoView();
                },
                false,
            );
        });
    },
    false,
);
