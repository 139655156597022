document.addEventListener('DOMContentLoaded', () => {
    document.getElementsByTagName('body')[0].classList.remove('preload');

    const cityButton = document.getElementById('city_button');
    const cityDropdown = document.getElementById('city_dropdown');
    const citySearch = document.getElementById('city_search');
    const menuButton = document.getElementById('menu_button');
    const menuDropdown = document.getElementById('menu_dropdown');
    const countryButton = document.getElementById('country_button');
    const countryDropdown = document.getElementById('country_dropdown');
    const mbiButton = document.getElementById('menu_bar_items_button');
    const mbiDropdown = document.getElementById('menu_bar_items_dropdown');

    if (cityButton && citySearch) {
        cityButton.addEventListener('click', (e) => {
            e.stopPropagation();
            if (cityDropdown.classList.contains('fade-in')) {
                cityDropdown.classList.add('fade-out');
                cityDropdown.classList.remove('fade-in');
                cityButton.classList.remove('is-active');
            } else {
                cityDropdown.classList.add('fade-in');
                cityDropdown.classList.remove('fade-out');
                cityButton.classList.add('is-active');
            }

            closeMenuDropdown();
            closeMbiDropdown();
            closeCountryDropdown();
        });
        cityDropdown.addEventListener('click', (e) => {
            e.stopPropagation();
        });

        citySearch.addEventListener('input', (e) => {
            let filterValue = e.target.value.toLowerCase();
            let cities = cityDropdown.getElementsByTagName('a');
            let city;
            for (let i = 0; i < cities.length; i++) {
                city = cities[i].innerText;
                if (city.toLowerCase().indexOf(filterValue) > -1) {
                    cities[i].style.display = '';
                } else {
                    cities[i].style.display = 'none';
                }
            }
        });
    }

    if (menuButton && menuDropdown) {
        menuButton.addEventListener('click', (e) => {
            e.stopPropagation();
            if (menuDropdown.classList.contains('fade-in')) {
                menuDropdown.classList.add('fade-out');
                menuDropdown.classList.remove('fade-in');
            } else {
                menuDropdown.classList.add('fade-in');
                menuDropdown.classList.remove('fade-out');
            }

            closeCountryDropdown();
            closeCityDropdown();
            closeMbiDropdown();
        });

        menuDropdown.addEventListener('click', (e) => {
            e.stopPropagation();
        });
    }

    countryButton.addEventListener('click', (e) => {
        e.stopPropagation();
        if (countryDropdown.classList.contains('fade-in')) {
            countryDropdown.classList.add('fade-out');
            countryDropdown.classList.remove('fade-in');
        } else {
            countryDropdown.classList.add('fade-in');
            countryDropdown.classList.remove('fade-out');
        }
        closeMenuDropdown();
        closeCityDropdown();
        closeMbiDropdown();
    });

    if (mbiButton && mbiDropdown) {
        mbiButton.addEventListener('click', (e) => {
            e.stopPropagation();
            if (mbiDropdown.classList.contains('fade-in')) {
                mbiDropdown.classList.add('fade-out');
                mbiDropdown.classList.remove('fade-in');
                mbiButton.classList.remove('is-active');
            } else {
                mbiDropdown.classList.add('fade-in');
                mbiDropdown.classList.remove('fade-out');
                mbiButton.classList.add('is-active');
            }
            closeMenuDropdown();
            closeCityDropdown();
            closeCountryDropdown();
        });
    }

    document.querySelector('html').addEventListener('click', () => {
        //close dropdown if click on html
        closeAllDropdowns();
    });

    let links = menuDropdown ? menuDropdown.querySelectorAll('[data-scroll]') : [];
    let hasFoundTarget = false;
    links.forEach((link) => {
        const target = document.querySelector(link.getAttribute('data-scroll'));
        if (!target) {
            link.remove();
        } else {
            hasFoundTarget = true;
        }
    });
    if (!hasFoundTarget && menuButton) {
        menuButton.remove();
    }

    links = document.querySelectorAll('[data-scroll]');
    links.forEach((link) => {
        link.addEventListener(
            'click',
            (e) => {
                e.preventDefault();
                const href = e.target.getAttribute('data-scroll');
                const target = document.querySelector(href);

                closeMenuDropdown();
                if (!target) {
                    return; // exit if no target found (e.g. not found page also has menu)
                }

                const rect = target.getBoundingClientRect();
                const scrollTop = window.pageYOffset || document.documentElement.scrollTop;

                scroll({
                    top: rect.top + scrollTop,
                    behavior: 'smooth',
                });
            },
            false,
        );
    });

    function closeMenuDropdown() {
        if (menuDropdown) {
            menuDropdown.classList.remove('fade-in');
            menuDropdown.classList.add('fade-out');
        }
    }

    function closeCityDropdown() {
        if (cityDropdown && cityButton) {
            cityDropdown.classList.remove('fade-in');
            cityDropdown.classList.add('fade-out');
            cityButton.classList.remove('is-active');
        }
    }

    function closeCountryDropdown() {
        if (countryDropdown) {
            countryDropdown.classList.remove('fade-in');
            countryDropdown.classList.add('fade-out');
        }
    }

    function closeMbiDropdown() {
        if (mbiButton && mbiDropdown) {
            mbiDropdown.classList.remove('fade-in');
            mbiDropdown.classList.add('fade-out');
            mbiButton.classList.remove('is-active');
        }
    }

    function closeAllDropdowns() {
        closeMenuDropdown();
        closeCityDropdown();
        closeCountryDropdown();
        closeMbiDropdown();
    }
});
