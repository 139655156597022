import { getCookie } from '../cookies';

export default class TrackingScript {
    constructor() {
        this.needsCookies = true;
    }

    async allowedInject(once = false) {
        // Skip cookie consent check if no cookies are needed
        if (!this.needsCookies) {
            return true;
        }

        return await this.allowedConsent(once);
    }

    async allowedConsent(once = false) {
        let consent = this.getConsent();

        if (!consent && once) {
            // No consent given and we are not going to wait for it
            return false;
        }

        // Wait for cookie consent given
        while (!consent) {
            await timeout(1);
            consent = this.getConsent();
        }

        return true;
    }

    getConsent() {
        return getCookie('cookieConsent') === 'full';
    }

    hasCookieConsentForNewBanner() {
        return getCookie('cookieConsentCourier') !== null;
    }

    /**
     * This method gets the city value for tracking.
     * @returns {string} The city value.
     */
    getCityValue() {
        let cityTracking;
        let urlParams = new URLSearchParams(window.location.search);
        let cityCode = window.currentCity?.tracking_name;

        if (cityCode && (window.page === 'home_city' || urlParams.get('page') === 'city')) {
            cityTracking = cityCode;
        } else if (cityCode) {
            cityTracking = cityCode + ' - Country';
        } else {
            cityTracking = 'Country';
        }

        return cityTracking;
    }
}

function timeout(seconds) {
    return new Promise((resolve) => setTimeout(resolve, seconds * 1000));
}
