import { v4 as uuidv4 } from 'uuid';

// Recruitment Anonymous User ID
const LOCALSTORAGE_ITEM_KEY = 'recruitment-auid';

function generateUserId() {
    return uuidv4();
}

function saveUserId(userId) {
    try {
        localStorage.setItem(LOCALSTORAGE_ITEM_KEY, userId);
    } catch (e) {
        //
    }
}

export function getUserId() {
    try {
        const savedUserId = localStorage.getItem(LOCALSTORAGE_ITEM_KEY);

        if (savedUserId) {
            return savedUserId;
        }
    } catch (e) {
        //
    }

    const userId = generateUserId();
    saveUserId(userId);

    return userId;
}
