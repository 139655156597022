<script>
export default {
  methods: {
    translate(key, terms = {}) {
      let translation = this.language.translations[key] || key;
      if (translation !== key) {
        let internalTerms = translation.match(/@:\S+/g);
        if (internalTerms) {
          internalTerms.forEach((term) => {
            const realTerm = this.language.translations[term.substr(2)] || term.substr(2);
            translation = translation.replace(new RegExp(term, 'g'), realTerm);
          });
        }
      }
      Object.keys(terms).forEach((term) => {
        translation = translation.replace(new RegExp(`{${term}}`, 'g'), terms[term]);
      });
      return translation;
    },
  },
};
</script>
