document.addEventListener('DOMContentLoaded', function () {
    const languages = document.querySelectorAll('.language_list_item');

    // Add active class to current language
    if (languages) {
        languages.forEach((language) => {
            if (language.firstElementChild.firstElementChild.classList.value.split('--')[1] === window.language.code) {
                language.firstElementChild.classList.add('is-active');
            }
        });
    }
});
