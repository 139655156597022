document.addEventListener('DOMContentLoaded', () => {
    document.getElementsByTagName('body')[0].classList.remove('preload');

    const cityButton = document.getElementById('footer_city_button');
    const cityDropdown = document.getElementById('footer_city_dropdown');

    if (cityButton) {
        cityButton.addEventListener('click', () => {
            if (cityDropdown.classList.contains('is-active')) {
                cityDropdown.classList.remove('is-active');
                cityButton.classList.remove('is-active');
            } else {
                cityDropdown.classList.add('is-active');
                cityButton.classList.add('is-active');
            }
        });
    }
});
