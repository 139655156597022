import Tealium from './tealium';
import Awin from './awin';
import GoogleTagManager from './google-tag-manager';

/**
 * Returns the linker parameters for Google Analytics to track users across different domains
 * @see https://gist.github.com/holmberd/b0b9c30d11d8c9cd8abd799ffd9e38dc
 */
export function getLinkerParam() {
    return getGaParam('linkerParam');
}

export function getClientId() {
    return getGaParam('clientId');
}

export function getTrackingId() {
    return getGaParam('trackingId');
}

function getGaParam(name) {
    try {
        return window.ga ? window.ga.getAll()[0].get(name) : null;
    } catch (err) {
        if (process.env.NODE_ENV !== 'production') {
            console.log('Unable to get ' + name + ' parameter:', err.message); // eslint-disable-line no-console
        }
    }
    return '';
}

export const tealium = new Tealium();
export const googleTagManager = new GoogleTagManager();
export const awin = new Awin();
