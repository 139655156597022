const svgCloseIcon = `<svg width="31" height="3" viewBox="0 0 31 3" fill="none" xmlns="http://www.w3.org/2000/svg">
<path d="M1.40601 1.69519H18.8944" stroke="#FF8000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
</svg>
`;
const svgOpenIcon = `
<svg width="31" height="33" viewBox="0 0 31 33" fill="none" xmlns="http://www.w3.org/2000/svg">
<path d="M15.1504 7.52344V25.8671" stroke="#FF8000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
<path d="M6.40625 16.6953H23.8946" stroke="#FF8000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
</svg>`;

document.addEventListener('DOMContentLoaded', function () {
    const seoContent = document.querySelector('.seo_text--content');
    if (seoContent) {
        // check if the content contains headings
        [...seoContent.children].forEach((content) => {
            if (
                content.tagName == 'H2' ||
                content.tagName == 'H3' ||
                content.tagName == 'H4' ||
                content.tagName == 'H5' ||
                content.tagName == 'H6'
            ) {
                const headingContent = document.createElement('div');
                headingContent.classList.add('seo_text--heading');

                const collapseDiv = document.createElement('div');
                collapseDiv.classList.add('collapse__container', 'open');

                const heading = content.cloneNode(true);
                collapseDiv.appendChild(heading);

                const svg = document.createElement('div');
                svg.innerHTML = svgOpenIcon;
                svg.classList.add('collapse__icon');
                collapseDiv.appendChild(svg);

                headingContent.appendChild(collapseDiv);

                // add content under the heading
                const contentUnder = document.createElement('div');
                contentUnder.classList.add('collapse__content');

                let idx = 0;
                for (let i = 0; i < seoContent.children.length; i++) {
                    if (seoContent.children[i] == content) {
                        idx = i;
                        break;
                    }
                }

                for (let i = idx + 1; i < seoContent.children.length; i++) {
                    if (
                        seoContent.children[i].tagName != 'H2' &&
                        seoContent.children[i].tagName != 'H3' &&
                        seoContent.children[i].tagName != 'H4' &&
                        seoContent.children[i].tagName != 'H5' &&
                        seoContent.children[i].tagName != 'H6'
                    ) {
                        contentUnder.appendChild(seoContent.children[i].cloneNode(true));
                        seoContent.children[i].remove();
                        i--;
                    } else {
                        break;
                    }
                }
                if (contentUnder.children.length > 0) {
                    headingContent.appendChild(contentUnder);
                }

                content.parentNode.insertBefore(headingContent, content);
                content.remove();
            }
        });

        const collapseHeadings = document.querySelectorAll('.seo_text--heading');
        if (collapseHeadings) {
            collapseHeadings.forEach((content) => {
                content.childNodes.forEach((child) => {
                    if (!child.classList.contains('collapse__container')) {
                        child.classList.add('hide');
                    }
                });
            });
        }

        const headers = document.querySelectorAll('.seo_text--heading');
        headers.forEach((header) => {
            header.addEventListener('click', () => {
                if (header.childNodes[0].classList.contains('close')) {
                    header.childNodes[0].classList.remove('close');
                    header.childNodes[0].classList.add('open');
                    header.childNodes[0].childNodes[1].innerHTML = svgOpenIcon;
                } else if (header.childNodes[0].classList.contains('open')) {
                    header.childNodes[0].classList.remove('open');
                    header.childNodes[0].classList.add('close');
                    header.childNodes[0].childNodes[1].innerHTML = svgCloseIcon;
                }

                header.childNodes.forEach((child, idx) => {
                    if (idx > 0) {
                        child.classList.toggle('hide');
                    }
                });
            });
        });
    }
});
