// Wait for Vue to be loaded

let hideFlagDom = false;
let hideFlagVue = false;
const applyBar = document.getElementById('sticky_apply_now');

function hideStickyApplyNowBar() {
    // hide if anything else is visible
    if (hideFlagDom || hideFlagVue) {
        applyBar && applyBar.classList.add('hide');
    }
}

function showStickyApplyNowBar() {
    // show if nothing else is visible
    if (!hideFlagDom && !hideFlagVue) {
        applyBar && applyBar.classList.remove('hide');
    }
}

document.addEventListener('DOMContentLoaded', () => {
    const salaryCalculator = document.getElementById('salary-calculator');
    const applyButtonTop = document.getElementById('application_button');
    const blogCategoryApply = document.getElementById('blog-category-apply');

    // if there are no buttons to observe
    if (!salaryCalculator && !applyButtonTop && !blogCategoryApply) {
        showStickyApplyNowBar();
    }

    // Hide bar when the apply now button is clicked
    let options = {
        root: null,
        rootMargin: '0px',
        threshold: [0, 0.01, 0.1, 0.2, 0.3, 0.4, 0.5, 0.6, 0.7, 0.8, 0.9, 0.95],
    };

    let observer = new IntersectionObserver(intersectionCallback, options);

    // Specific order, signup observer needs to fire after the apply button.
    salaryCalculator && observer.observe(salaryCalculator);
    applyButtonTop && observer.observe(applyButtonTop);
    blogCategoryApply && observer.observe(blogCategoryApply);

    function intersectionCallback(entries) {
        if (entries.length > 1) {
            let salaryCalculatorVisible = false;
            let topButtonVisible = false;
            let blogCategoryApplyVisible = false;
            entries.forEach((entry) => {
                switch (entry.target) {
                    case salaryCalculator:
                        salaryCalculatorVisible = entry.intersectionRatio > 0.2;
                        break;
                    case applyButtonTop:
                        topButtonVisible = entry.intersectionRatio > 0.1;
                        break;
                    case blogCategoryApply:
                        blogCategoryApplyVisible = entry.intersectionRatio > 0.8;
                        break;
                }
                if (salaryCalculatorVisible || topButtonVisible || blogCategoryApplyVisible) {
                    hideFlagDom = true;
                    hideStickyApplyNowBar();
                } else {
                    hideFlagDom = false;
                    showStickyApplyNowBar();
                }
            });
        } else {
            entries.forEach((entry) => {
                switch (entry.target) {
                    case salaryCalculator:
                        if (entry.intersectionRatio > 0.2) {
                            hideFlagDom = true;
                            hideStickyApplyNowBar();
                        } else {
                            hideFlagDom = false;
                            showStickyApplyNowBar();
                        }
                        break;
                    case applyButtonTop:
                        if (entry.intersectionRatio > 0.1) {
                            hideFlagDom = true;
                            hideStickyApplyNowBar();
                        } else {
                            hideFlagDom = false;
                            showStickyApplyNowBar();
                        }
                        break;
                    case blogCategoryApply:
                        if (entry.intersectionRatio > 0.8) {
                            hideFlagDom = true;
                            hideStickyApplyNowBar();
                        } else {
                            hideFlagDom = false;
                            showStickyApplyNowBar();
                        }
                        break;
                }
            });
        }
    }
});

// for apply buttons that are inside Vue components
document.addEventListener('vueApplyButtonVisibilityChanged', (e) => {
    if (e.detail.isVisible === true) {
        hideFlagVue = true;
        hideStickyApplyNowBar();
    } else {
        hideFlagVue = false;
        showStickyApplyNowBar();
    }
});
