import { createFeatureManager } from '../libs/feature-management/feature-management.mjs';
import { getUserId } from './user-id-provider';
import { googleTagManager, tealium } from './tracking';

const activatedExperiments = {};

const trackExperiment = (key, variant) => {
    activatedExperiments[key] = variant;
    tealium.trackJetfmExperiment(key, variant);
    googleTagManager.trackEventWithName('jetfm_experiment', {
        customData: {
            experimentId: key,
            variant: variant,
        },
    });
};

const featureManagement = createFeatureManager({
    keyPrefix: window.jetfm.keyPrefix,
    cdn: {
        scope: window.jetfm.scope,
        environment: window.jetfm.environment,
        key: window.jetfm.key,
        poll: false,
    },
    getContext: () => ({
        restrictionsProviders: new Map().set('Country', () => window.country.code.toUpperCase()),
        anonUserId: getUserId(),
    }),
    onTrack: (key, variant) => trackExperiment(key, variant),
});

const getFeature = async (key, type) => {
    const getFeatureValue = (key, type) => {
        switch (type) {
            case Boolean:
                return featureManagement.getBooleanValue(key);
            case Number:
                return featureManagement.getIntegerValue(key);
            case String:
                return featureManagement.getStringValue(key);
        }
    };
    try {
        const searchParams = new URLSearchParams(window.location.search);
        if (searchParams.has(key)) {
            const variant = type(searchParams.get(key));

            console.info(`Forced: ${key} => ${variant}`);

            trackExperiment(key, variant);

            return new Promise((resolve) => resolve(variant));
        }
        await featureManagement.loadFromCdn();

        return getFeatureValue(key, type);
    } catch (error) {
        // TODO: Add logging code and fallback value.
        console.error(error);
    }
};

export const getActivatedExperiments = () => {
    return Object.entries(activatedExperiments)
        .map(([key, variant]) => `${key}=${variant}`)
        .join(';');
};

/**
 * @returns {Promise<'control'| 'variant'>}
 */
export const getAATestFeatureValue = () => {
    return getFeature('fm_scoober_recruitment_aa_test', String);
};

/**
 * @returns {Promise<'control'| 'multi_step_3_steps' | 'multi_step_complete'>}
 */
export const getMultiStepFormFeatureValue = () => {
    return getFeature('fm_scoober_recruitment_multi_step_form', String);
};

export const getSalarySliderFeatureValue = () => {
    return getFeature('fm_scoober_recruitment_salary_slider', String);
};
