/**
 * Checks if a certain type of cookie is accepted (necessary, functional, analytical or personalized)
 */
export function cookiesAccepted(name) {
    // If the user has not accepted any cookies return false
    const consentLevelCookie = getJsonCookie('cookieConsentCourier');
    if (consentLevelCookie === null) {
        return false;
    }

    let cookieConsentLevel = consentLevelCookie.find((levelCookie) => {
        return levelCookie.consentTypeName === name;
    });

    return cookieConsentLevel.isAccepted;
}

export function getCookie(name, defaultValue = null) {
    let cookie = document.cookie.split('; ').find((item) => item.startsWith(name + '='));

    if (cookie) {
        return cookie.replace(name + '=', '');
    }

    return defaultValue;
}

export function getJsonCookie(name, defaultValue = null) {
    let cookie = getCookie(name);

    if (cookie) {
        try {
            return JSON.parse(cookie);
        } catch (e) {
            console.error('Error parsing cookie', cookie, name, defaultValue);
            return defaultValue;
        }
    }

    return defaultValue;
}

export function setCookie(name, value) {
    let expiryDate = new Date();
    expiryDate.setDate(expiryDate.getDate() + 90);

    let cookie = name + '=' + value + ';path=/;expires=' + expiryDate.toUTCString();
    cookie += window.location.protocol === 'https:' ? ';secure' : '';
    document.cookie = cookie;
}

export function setJsonCookie(name, value) {
    setCookie(name, JSON.stringify(value));
}

export function removeCookie(name) {
    document.cookie = name + '=; expires=Thu, 01 Jan 1970 00:00:00 UTC; path=/;';
}
