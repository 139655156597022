import TrackingScript from './tracking-script';

const AWIN_MASTER_TAG_ID = 'awin_master_tag';
const AWIN_FALLBACK_PIXEL_ID = 'awin_fallback_pixel';

/**
 * Implements the Awin advertisement conversion tracking tag
 */
export default class Awin extends TrackingScript {
    constructor() {
        super();

        this.needsCookies = true;
        this.disabled = false;
        this.options = {};
    }

    connectAxios(axios) {
        this.axios = axios;
    }

    setOptions(options) {
        options = options || {};
        Object.assign(this.options, options);
    }

    resetOptions() {
        this.options = {
            channel: 'aw',
            amount: '1',
        };
    }

    /**
     * Injects the Awin tag and pixel
     * @param conversion - Can disable conversion tracking when only the master tag is needed
     */
    async inject(conversion = true) {
        let inject = true;
        if (this.disabled || !(await this.allowedInject(true))) {
            inject = false;
        }

        // Clean previous injections
        removeElement(AWIN_MASTER_TAG_ID);
        removeElement(AWIN_FALLBACK_PIXEL_ID);
        window.AWIN = {};

        if (conversion && checkOptions(this.options)) {
            // inject full conversion
            this.options.amount = '1';
            this.options.parts = `${this.options.group}:${this.options.amount}`;
            setSale(this.options);
            if (inject) {
                injectMasterTag(this.options);
                injectFallbackTag(this.options);
            }
        } else if (!conversion && this.options.advertiser_id && inject) {
            // only inject master tag
            injectMasterTag({ advertiser_id: this.options.advertiser_id });
        }
    }
}

function cleanURL(template = '') {
    return encodeURI(template.replace(/\s/g, ''));
}

function removeElement(elementId) {
    try {
        const element = document.getElementById(elementId);
        element.parentNode.removeChild(element);
    } catch (e) {
        //
    }
}

function checkOptions(options) {
    if (
        !(
            options &&
            options.advertiser_id &&
            options.currency &&
            options.amount &&
            options.group &&
            options.orderRef &&
            options.channel
        )
    ) {
        console.error('invalid or missing options for injecting Awin tag');
        return false;
    } else {
        return true;
    }
}

function setSale(options) {
    window.AWIN = {
        Tracking: {
            Sale: {
                amount: options.amount,
                channel: options.channel,
                orderRef: options.orderRef,
                parts: options.parts,
                currency: options.currency,
            },
        },
    };
}

function injectMasterTag(options) {
    // master tag
    const masterTag = document.createElement('script');
    masterTag.id = AWIN_MASTER_TAG_ID;
    masterTag.defer = true;
    masterTag.src = `https://www.dwin1.com/${options.advertiser_id}.js`;
    document.body.appendChild(masterTag);
}

function injectFallbackTag(options) {
    // fallback pixel
    const awPixel = new Image(0, 0);
    awPixel.id = AWIN_FALLBACK_PIXEL_ID;
    awPixel.src = cleanURL(`
        https://www.awin1.com/sread.img?tt=ns&tv=2
        &merchant=${options.advertiser_id}
        &amount=${options.amount}
        &ch=${options.channel}
        &parts=${options.parts}
        &ref=${options.orderRef}
        &cr=${options.currency}
        &testmode=0
    `);
}
