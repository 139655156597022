import { getPersistentItem, setPersistentItem } from './persistence';
import { cookiesAccepted } from './cookies';

const LOCALSTORAGE_ITEM_KEY = 'recruitment_saved_query_parameters';

export function getUtmData() {
    let current = getSavedQueryParams();
    let utmData = {};

    if (cookiesAccepted('analytical')) {
        Object.keys(current).map((key) => {
            if (key.startsWith('utm_') && key !== 'utm_referrer') {
                utmData[key] = current[key];
            }
        });

        if (utmData.utm_source === 'blog-lieferando' && utmData.utm_medium === 'referral') {
            utmData.utm_source = 'google';
            utmData.utm_medium = 'organic';
        }
        if (current.gclid) {
            utmData.utm_source = 'Google';
            utmData.utm_medium = 'cpc';
            utmData.utm_content = `GCLID: ${current.gclid}`;
        }
        if (current.utm_referrer && !utmData.utm_source && !utmData.utm_medium && !utmData.utm_content) {
            utmData.utm_source = current.utm_referrer;
            if (/google|bing/i.test(utmData.utm_source)) {
                utmData.utm_medium = 'organic';
            }
        }
    }

    return utmData;
}

export function saveQueryParams() {
    let urlParams = new URLSearchParams(window.location.search);
    let current = getSavedQueryParams();

    let storedCityParamPresent = Object.keys(current).includes('city');
    let urlCityParamPresent = false;
    urlParams.forEach((value, key) => {
        if (key === 'city') {
            urlCityParamPresent = true;
        }
        current[key] = value;
    });
    // if no ?city= present in url, then delete it from cache
    if (!urlCityParamPresent) {
        delete current['city'];
    }
    let newCityParamPresent = !storedCityParamPresent && urlCityParamPresent;

    if (document.referrer) {
        // Only save the data if it's from a different host then the current page
        let referrerUrl = new URL(document.referrer);
        if (referrerUrl.hostname !== window.location.hostname) {
            current.utm_referrer = referrerUrl.hostname;
        }
    }
    setPersistentItem(LOCALSTORAGE_ITEM_KEY, current);

    return newCityParamPresent;
}

export function getQueryParam(key) {
    let current = getSavedQueryParams();

    return current[key] ?? undefined;
}

export function getQueryParamKeys() {
    return Object.keys(getSavedQueryParams());
}

function getSavedQueryParams() {
    return getPersistentItem(LOCALSTORAGE_ITEM_KEY) || {};
}
