import { cookiesAccepted } from './cookies';
/**
 * Time in hours the persisted data will remain valid before it will be deleted
 * @type {number}
 */
const TIME_TO_EXPIRE_IN_HOURS = 48;
/**
 * Global key used in local storage in which all data of this module in stored
 * @type {string}
 */
const PERSISTENCE_KEY = 'persistence_module_v1';
/**
 * Array of string which cannot be used when setting an item in storage
 * @type {string[]}
 */
const RESERVED_KEYS = ['updated_at'];

/**
 * Returns an item from local storage if the item is not expired; otherwise, returns null.
 * @param {string} key The key of the item
 * @returns {any} The stored value or null if expired or not found.
 */
export function getPersistentItem(key) {
    // If the user has not accepted functional cookies, don't access local storage
    if (!cookiesAccepted('functional')) {
        return;
    }

    const storedJSON = localStorage.getItem(PERSISTENCE_KEY);
    if (!storedJSON) return null;

    let storedData;
    try {
        storedData = JSON.parse(storedJSON);
    } catch (e) {
        return null;
    }

    if (!storedData[key]) {
        return null;
    }

    const storedDate = new Date(storedData.updated_at);
    const currentDate = new Date();

    // Calculate the time difference in milliseconds
    const timeDifference = currentDate - storedDate;
    // Convert time difference to hours
    const timeDifferenceInHours = timeDifference / (1000 * 60 * 60);

    if (timeDifferenceInHours <= TIME_TO_EXPIRE_IN_HOURS) {
        return storedData[key];
    } else {
        clearAllPersistentItems();
        return null;
    }
}

/**
 * Parses the value to JSON and stores it in local storage.
 *
 * @param {string} key - The key for the item.
 * @param {any} value - The value to be stored.
 * @throws {Error} Will throw an error if key is a reserved key
 */
export function setPersistentItem(key, value) {
    // If the user has not accepted functional cookies, don't access local storage
    if (!cookiesAccepted('functional')) {
        return;
    }

    if (RESERVED_KEYS.includes(key)) {
        throw new Error('Cannot set reserved key');
    }
    const currentDate = new Date();
    let dataJSON = localStorage.getItem(PERSISTENCE_KEY);

    if (!dataJSON) {
        dataJSON = '{}';
    }
    let data;
    try {
        data = JSON.parse(dataJSON);
    } catch (e) {
        data = {};
    }

    data[key] = value;
    data['updated_at'] = currentDate.toISOString();

    localStorage.setItem(PERSISTENCE_KEY, JSON.stringify(data));
}

/**
 * Clear a specific key from persistent module
 *
 * @param {string} key - The key to be cleared.
 */
export function clearPersistentItem(key) {
    const storedJSON = localStorage.getItem(PERSISTENCE_KEY);

    if (!storedJSON) return;

    let storedData;
    try {
        storedData = JSON.parse(storedJSON);
    } catch (e) {
        return;
    }

    if (!storedData[key]) {
        return;
    }
    delete storedData[key];

    localStorage.setItem(PERSISTENCE_KEY, JSON.stringify(storedData));
}

/**
 * Clears all persistent items set by the module
 */
export function clearAllPersistentItems() {
    localStorage.removeItem(PERSISTENCE_KEY);
}
