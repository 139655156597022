<template>
  <div class="hamburger-button-box">
    <div
      class="hamburger_menu_button"
      id="hamburger_menu_button"
      @click="openContainer()"
      @keyup.enter="openContainer()"
      tabindex="0"
    >
      <img :src="HamburgerMenuIcon" loading="lazy" alt="" />
    </div>
    <div
      class="hamburger_menu"
      id="hamburger_menu_container"
      ref="hamburger_menu_container"
      :class="{ 'fade-in': hamburgerMenuOpened === true, 'fade-out': hamburgerMenuOpened === false }"
    >
      <div class="container__overlay" @click="closeContainer()">
        <div class="container__box" @click.stop="">
          <div class="container_top" :class="{ container_top_return: baseMenuOpened === false }">
            <a
              class="container__close"
              v-if="baseMenuOpened"
              @click="closeContainer()"
              @keyup.enter="closeContainer()"
              :style="hamburgerMenuOpened ? { visibility: 'visible' } : { visibility: 'hidden' }"
              tabindex="0"
            >
              <img :src="CloseIcon" loading="lazy" alt="" />
            </a>
            <a
              class="container__return"
              v-if="!baseMenuOpened"
              @click="returnToSelection()"
              @keyup.enter="returnToSelection()"
              tabindex="0"
            >
              <img :src="ReturnButtonIcon" loading="lazy" alt="" />
            </a>
          </div>
          <div class="container__content">
            <div class="container_content_selection" v-if="baseMenuOpened">
              <div class="container_content_inside_track" @click="insideTrackClicked()" v-if="hasInsideTrack">
                <div class="container_content_selection_line">
                  <span class="container-content_category">{{ translate('home.courier_hub') }}</span>
                  <div class="container_content_line_right">
                    <img :src="DownArrowIcon" loading="lazy" alt="" />
                  </div>
                </div>
              </div>
              <div class="container_content_city" v-if="isCitySelected" @click="cityClicked()">
                <div class="container_content_selection_line">
                  <span class="container-content_category">{{ translate('menu.city') }}</span>
                  <div class="container_content_line_right">
                    <span class="container_content_selected_category">{{ city.name }}</span>
                  </div>
                </div>
              </div>
              <div class="container_content_country_language">
                <div class="container_content_selection_line container_content_line_separator" @click="countyClicked()">
                  <span class="container-content_category">{{ translate('menu.country') }}</span>
                  <div class="container_content_line_right">
                    <span class="container_content_selected_category">
                      {{ translate('countries.' + country.code) }}
                    </span>
                  </div>
                </div>
                <div class="container_content_selection_line" @click="languageClicked()">
                  <span class="container-content_category">{{ translate('menu.language') }}</span>
                  <div class="container_content_line_right">
                    <span class="container_content_selected_category"> {{ language.name }}</span>
                  </div>
                </div>
              </div>
            </div>
            <HamburgerMenuOptions :options="menuItems" v-if="insideTrackOpened"></HamburgerMenuOptions>
            <HamburgerMenuOptions
              :search-option="true"
              :options="cityOptions"
              :selected-option="city.name"
              :language="language"
              v-if="citiesOpened"
            ></HamburgerMenuOptions>
            <HamburgerMenuOptions
              :selected-option="language.name"
              :options="languageOptions"
              v-if="languageOptionsOpened"
            ></HamburgerMenuOptions>
            <HamburgerMenuOptions
              :selected-option="translate('countries.' + country.code)"
              :options="countries"
              v-if="countryOptionsOpened"
            ></HamburgerMenuOptions>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import HamburgerMenuIcon from '../../../img/header/hamburger_menu.svg';
import CloseIcon from '../../../img/navigation/close.svg';
import ReturnButtonIcon from '../../../img/navigation/return_button.svg';
import DownArrowIcon from '../../../img/navigation/down_arrow.svg';
import TranslateMixin from '../TranslateMixin.vue';
import HamburgerMenuOptions from './HamburgerMenuOptions.vue';
import { translate } from '../../modules/translate';
export default {
  name: 'HeaderHamburgerMenu',
  components: { HamburgerMenuOptions },
  data() {
    return {
      HamburgerMenuIcon,
      CloseIcon,
      ReturnButtonIcon,
      DownArrowIcon,
      teleportTargetId: '#class__container',
      language: window.language,
      country: window.country,
      hamburgerMenuOpened: false,
      baseMenuOpened: false,
      isCitySelected: false,
      city: '',

      menuItems: window.language.menuItems.map((menuItem) => ({
        id: menuItem.id,
        name: menuItem.label,
        url: menuItem.url,
      })),
      cityOptions: window.language.city_options.map((city_option) => ({
        id: city_option.id,
        name: city_option.name,
        url: window.homePath + '/' + city_option.slug,
      })),
      languageOptions: window.country.languages.map((language) => ({
        id: language.id,
        name: language.name,
        url: language.url,
        code: language.code,
      })),
      countries: window.countries.map((country) => ({
        id: country.id,
        name: translate('countries.' + country.code),
        url: country.url,
      })),

      insideTrackOpened: false,
      citiesOpened: false,
      languageOptionsOpened: false,
      countryOptionsOpened: false,
    };
  },
  mounted() {
    let target = document.querySelector(this.$data.teleportTargetId);
    this.baseMenuOpened = true;
    this.hasCitySelected();
    target.appendChild(this.$refs.hamburger_menu_container);
  },
  computed: {
    hasInsideTrack() {
      return this.menuItems.length > 0;
    },
  },

  methods: {
    hasCitySelected() {
      if (window.currentCity) {
        this.isCitySelected = true;
        this.city = window.currentCity;
      }
    },

    insideTrackClicked() {
      this.insideTrackOpened = true;
      this.baseMenuOpened = false;
    },

    cityClicked() {
      this.citiesOpened = true;
      this.baseMenuOpened = false;
    },

    countyClicked() {
      this.countryOptionsOpened = true;
      this.baseMenuOpened = false;
    },

    languageClicked() {
      this.languageOptionsOpened = true;
      this.baseMenuOpened = false;
    },

    openContainer() {
      this.$data.hamburgerMenuOpened = true;
      document.documentElement.style.setProperty('overflow', 'hidden');
    },

    closeContainer() {
      this.$data.hamburgerMenuOpened = false;
      document.documentElement.style.setProperty('overflow', 'visible');
    },

    returnToSelection() {
      this.baseMenuOpened = true;
      this.insideTrackOpened = false;
      this.citiesOpened = false;
      this.languageOptionsOpened = false;
      this.countryOptionsOpened = false;
    },
  },
  mixins: [TranslateMixin],
};
</script>
