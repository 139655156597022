import { googleTagManager, tealium } from './tracking';

document.addEventListener('DOMContentLoaded', () => {
    //footer link
    const footerLinks = document.querySelectorAll('.footer__item');
    footerLinks.forEach((footerLink) => {
        footerLink.addEventListener('click', () => {
            tealium.hasCLickedFooterLink();
            googleTagManager.trackEventWithName('hasClickedFooterLink');
        });
    });

    // get the buttons with tracking events
    const stickyApplyNowRedirect = document.getElementById('sticky_apply_now__button_redirect');
    const stickyApplyNow = document.getElementById('sticky_apply_now__button');
    const signupButton = document.getElementById('signup_form_button');
    const titlePageButton = document.getElementById('application_button');

    if (stickyApplyNowRedirect) {
        stickyApplyNowRedirect.addEventListener('click', (e) => {
            delayHrefClick(e);
            tealium.cta_apply_form();
            googleTagManager.trackCtaApplyForm();
        });
    }

    if (stickyApplyNow) {
        stickyApplyNow.addEventListener('click', (e) => {
            delayHrefClick(e);
            tealium.cta_apply_form();
            googleTagManager.trackCtaApplyForm();
        });
    }

    if (signupButton) {
        signupButton.addEventListener('click', (e) => {
            delayHrefClick(e);
            tealium.cta_apply_form();
            googleTagManager.trackCtaApplyForm();
        });
    }

    if (titlePageButton) {
        titlePageButton.addEventListener('click', (e) => {
            delayHrefClick(e);
            tealium.cta_apply_form();
            googleTagManager.trackCtaApplyForm();
        });
    }

    //language and country buttons
    const countryLinks = document.querySelectorAll('#country_dropdown .country_list_item');
    const languageLinks = document.querySelectorAll('.language_list_item');
    countryLinks.forEach((countryLink) => {
        countryLink.addEventListener('click', () => {
            tealium.hasChangedCountry();
            googleTagManager.trackEventWithName('hasChangedCountry');
        });
    });
    languageLinks.forEach((languageLink) => {
        languageLink.addEventListener('click', () => {
            tealium.hasChangedLanguage();
            googleTagManager.trackEventWithName('hasChangedLanguage');
        });
    });

    //faq section
    const faqItems = document.querySelectorAll("[id^='faq-trigger']");
    faqItems.forEach((faqItem) => {
        faqItem.addEventListener('click', () => {
            let checkbox = faqItem.previousElementSibling;
            if (checkbox.tagName.toLowerCase() === 'input') {
                if (checkbox.checked === false) {
                    //open
                    if (faqItem.id.includes('courier_hub')) {
                        tealium.hasOpenedFAQItemCourierHub(faqItem.firstElementChild.innerHTML);
                        googleTagManager.trackEventWithName('faq_open', {
                            customData: {
                                component_name: faqItem.firstElementChild.innerHTML,
                                component_type: 'faq',
                                component_action: 'open',
                            },
                        });
                    } else {
                        tealium.hasClickedFAQQuestion(faqItem.firstElementChild.innerHTML);
                        googleTagManager.trackEventWithName('hasOpenedFaqModal', {
                            customData: {
                                faqQuestion: faqItem.firstElementChild.innerHTML,
                            },
                        });
                    }
                } else if (checkbox.checked === true) {
                    //close
                    if (faqItem.id.includes('courier_hub')) {
                        tealium.hasClosedFAQItemCourierHub(faqItem.firstElementChild.innerHTML);
                        googleTagManager.trackEventWithName('faq_close', {
                            customData: {
                                component_name: faqItem.firstElementChild.innerHTML,
                                component_type: 'faq',
                                component_action: 'close',
                            },
                        });
                    } else {
                        tealium.hasClosedFAQModal(faqItem.firstElementChild.innerHTML);
                        googleTagManager.trackEventWithName('hasClosedFaqModal', {
                            customData: {
                                faqQuestion: faqItem.firstElementChild.innerHTML,
                            },
                        });
                    }
                }
            }
        });
    });
    const faqCloseButtons = document.querySelectorAll("[id^='faq-modal-close']");
    faqCloseButtons.forEach((faqCloseButton) => {
        faqCloseButton.addEventListener('click', () => {
            let question = faqCloseButton.parentElement.parentElement.querySelector('p').innerHTML;
            tealium.hasClosedFAQModal(question);
            googleTagManager.trackEventWithName('hasClosedFaqModal', {
                customData: {
                    faqQuestion: question,
                },
            });
        });
    });

    //testimonials
    const testimonials = document.getElementById('testimonials-row');
    if (testimonials) {
        const prevArrow = testimonials.querySelector('.carousel__arrow--prev');
        const nextArrow = testimonials.querySelector('.carousel__arrow--next');
        nextArrow.addEventListener('click', () => {
            tealium.hasClickedNextTestimonial();
            googleTagManager.trackEventWithName('hasClickedNextTestimonial');
        });
        prevArrow.addEventListener('click', () => {
            tealium.hasClickedPreviousTestimonial();
            googleTagManager.trackEventWithName('hasClickedPreviousTestimonial');
        });
    }

    // Courier Hub
    const category = document.getElementById('blog-category-title-id');
    const allCategories = document.querySelector('.hero__welcome--blog');

    // Help Center (subcategories)
    const helpCenterSubcategory = document.querySelector('.blog-category-help-center');

    if (category && !helpCenterSubcategory) {
        tealium.hasLoadedCategoryPage(category.textContent.trim());
        googleTagManager.trackEventWithName('general_category', {
            customData: {
                component_type: 'category',
                component_action: 'load',
                category_name: category.textContent.trim(),
            },
        });
    } else if (category && helpCenterSubcategory) {
        tealium.hasLoadedHelpCenterSubcategoryPage(category.textContent.trim());
        googleTagManager.trackEventWithName('help_center', {
            customData: {
                component_name: 'general_category',
                component_type: 'help_center',
                component_action: 'load',
                category_name: category.textContent.trim(),
            },
        });
    }

    if (allCategories) {
        tealium.hasLoadedCategoryPage('All categories');
        googleTagManager.trackEventWithName('general_category', {
            customData: {
                component_type: 'category',
                component_action: 'load',
                category_name: 'All categories',
            },
        });
    }

    // Article (blog post)
    const blogTitle = document.getElementById('blogPostTitleId');
    const blogCategory = document.getElementById('blogCategoryId');
    const blogContent = document.querySelector('.blog-section');
    const helpCenterArticle = document.getElementById('tracking-help-center-article');
    let scrolled50Percent,
        scrolled90percent = false;

    if (blogTitle && blogCategory) {
        tealium.hasLoadedArticlePage(
            helpCenterArticle ? blogCategory.textContent.trim() + ' - help center' : blogCategory.textContent.trim(),
            blogTitle.textContent.trim(),
        );
        googleTagManager.trackEventWithName('article', {
            customData: {
                component_type: 'article',
                component_action: 'load',
                category_name: helpCenterArticle
                    ? blogCategory.textContent.trim() + ' - help center'
                    : blogCategory.textContent.trim(),
                article_name: blogTitle.textContent.trim(),
            },
        });
    }

    document.addEventListener('scroll', () => {
        if (blogContent && (!scrolled50Percent || !scrolled90percent)) {
            if (window.innerHeight * 0.5 <= window.scrollY && !scrolled50Percent) {
                scrolled50Percent = true;
                tealium.hasScrolledArticlePage(50, blogTitle.textContent.trim());
                googleTagManager.trackEventWithName('scroll', {
                    customData: {
                        component_type: 'scroll',
                        article_name: blogTitle.textContent.trim(),
                        scroll_action: 50 + '%',
                    },
                });
            }
            if (window.innerHeight * 0.9 <= window.scrollY && !scrolled90percent) {
                scrolled90percent = true;
                tealium.hasScrolledArticlePage(90, blogTitle.textContent.trim());
                googleTagManager.trackEventWithName('scroll', {
                    customData: {
                        component_type: 'scroll',
                        article_name: blogTitle.textContent.trim(),
                        scroll_action: 90 + '%',
                    },
                });
            }
        }
    });

    // CTA Button Click
    const ctaButton = document.getElementById('home_ready_button_courier_hub');
    const stickyCtaButton = document.getElementById('home_ready_button_courier_hub_sticky');
    const ctaButtonContainer = document.getElementById('home_ready_button_courier_hub_interested');

    if (ctaButton) {
        ctaButton.addEventListener('click', () => {
            tealium.hasClickedCTAButton(blogTitle ? blogTitle.textContent.trim() : 'general');
            googleTagManager.trackCtaApply();
        });
    }

    if (stickyCtaButton) {
        stickyCtaButton.addEventListener('click', () => {
            tealium.hasClickedCTAButton(blogTitle ? blogTitle.textContent.trim() : 'general');
            googleTagManager.trackCtaApply();
        });
    }

    if (ctaButtonContainer) {
        ctaButtonContainer.addEventListener('click', () => {
            tealium.hasClickedCTAButton(blogTitle ? blogTitle.textContent.trim() : 'general');
            googleTagManager.trackCtaApply();
        });
    }

    // UEFA banner button
    const UEFAButton = document.getElementById('UEFA-button');
    if (UEFAButton) {
        UEFAButton.addEventListener('click', () => {
            tealium.hasClickedUEFAButton();
            googleTagManager.trackEventWithName('cta', {
                customData: {
                    component_name: window.location.href,
                    component_type: 'cta',
                    component_action: 'cta_uefa',
                },
            });
        });
    }

    // Country page scroll event
    const countryPage = document.getElementById('app');
    const isFormPage = window.page === 'form';

    let scrolledCountryPage50,
        scrolledCountryPage65,
        scrolledCountryPage80 = false;

    document.addEventListener('scroll', () => {
        if (countryPage && !isFormPage && (!scrolledCountryPage50 || !scrolledCountryPage80)) {
            let scrollPosition = window.scrollY;
            let windowSize = window.innerHeight;
            let bodyHeight = document.body.offsetHeight;
            let scrollPercentage = (scrollPosition / (bodyHeight - windowSize)) * 100;
            if (scrollPercentage >= 50 && !scrolledCountryPage50) {
                tealium.hasScrolledCountryPage(50);
                googleTagManager.trackEventWithName('scroll', {
                    customData: {
                        component_name: 'recruitment page',
                        component_type: 'scroll',
                        scroll_action: 50 + '%',
                    },
                });

                scrolledCountryPage50 = true;
            }
            if (scrollPercentage >= 65 && !scrolledCountryPage65) {
                tealium.hasScrolledCountryPage(65);
                googleTagManager.trackEventWithName('scroll', {
                    customData: {
                        component_name: 'recruitment page',
                        component_type: 'scroll',
                        scroll_action: 65 + '%',
                    },
                });
                scrolledCountryPage65 = true;
            }
            if (scrollPercentage >= 80 && !scrolledCountryPage80) {
                tealium.hasScrolledCountryPage(80);
                googleTagManager.trackEventWithName('scroll', {
                    customData: {
                        component_name: 'recruitment page',
                        component_type: 'scroll',
                        scroll_action: 80 + '%',
                    },
                });
                scrolledCountryPage80 = true;
            }
        }
    });
});

function delayHrefClick(event, delay = 100) {
    if (event.target.href) {
        event.preventDefault();
        setTimeout(function () {
            window.location = event.target.href;
        }, delay);
    }
}
