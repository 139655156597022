import { initSentryBrowser, initSentryVue } from './modules/sentry';
initSentryBrowser();

import.meta.glob(['../img/**']);

import './modules/tracking';
import './modules/unregister-service-workers';
import './modules/page-tracking-events';
import './modules/tracking-events';
import './modules/menu';
import './modules/carousel';
import './modules/videobox';
import './modules/modal';
import './modules/sticky-apply-now';
import './modules/footer';
import './modules/seo_accordion';
import './modules/header';
import './modules/seo_collapse';

// For the pie cookie banner styling
import '@justeattakeaway/pie-css';

import { saveQueryParams } from './modules/query_utils';
import { getAATestFeatureValue } from './modules/feature-management';
import { createPinia, PiniaVuePlugin } from 'pinia';
import HeaderHamburgerMenu from './components/hamburger_menu/HeaderHamburgerMenu.vue';
import Vue from 'vue';

initSentryVue(Vue);

(async () => {
    document.addEventListener('DOMContentLoaded', () => {
        import('./modules/cookie-banner');
        import('./modules/cookie-statement');

        // Check if element is in the viewport
        const isInViewport = (element) => {
            const imgSpace = element.getBoundingClientRect();
            return (
                imgSpace.top >= 0 && imgSpace.bottom <= (window.innerHeight || document.documentElement.clientHeight)
            );
        };

        // Logic for adding lazy-loading
        const allTheImages = document.querySelectorAll('img');
        allTheImages.forEach((image) => {
            if (isInViewport(image)) {
                image.setAttribute('loading', 'eager');
            } else {
                image.setAttribute('loading', 'lazy');
            }
        });

        // Animated images
        const animatedImages = document.querySelectorAll(`
            #day_in_a_life_video_section img,
            .what-you-need__image img,
            .carousel__slide:first-of-type .testimonial img,
            .carousel__slide:first-of-type .blog-index__category-post-image img,
            .blog-section img,
            .category__image img
        `);

        const observer = new IntersectionObserver((entries, observer) => {
            entries.forEach((entry) => {
                if (entry.isIntersecting) {
                    entry.target.classList.add('img-reveal'); // Apply animation class
                    observer.unobserve(entry.target); // Stop observing once animated
                }
            });
        });
        animatedImages.forEach((image) => {
            if (!isInViewport(image)) {
                observer.observe(image); // Only observe images outside the viewport
            }
        });
    });

    window.newCityParamPresent = saveQueryParams();

    if (document.getElementById('workingAreasMap')) {
        let WorkingAreasMap = (await import('./components/WorkingAreasMap.vue')).default;
        Vue.use(PiniaVuePlugin);
        const pinia = createPinia();
        new Vue({
            el: '#workingAreasMap',
            render: (h) => h(WorkingAreasMap),
            components: { WorkingAreasMap },
            pinia,
        });
    }

    if (document.getElementById('salary-calculator-container')) {
        Vue.use(PiniaVuePlugin);
        const pinia = createPinia();
        const SalaryCalculator = (await import('./components/salary-calculator/SalaryCalculator.vue')).default;
        new Vue({
            el: '#salary-calculator-container',
            render: (h) => h(SalaryCalculator),
            components: { SalaryCalculator },
            pinia,
        });
    }

    // HeaderHamburgerMenu is used on every page
    new Vue({
        el: '#hamburger-menu-mobile',
        render: (h) => h(HeaderHamburgerMenu),
        components: { HeaderHamburgerMenu },
    });

    if (document.getElementById('signup-page-container')) {
        Vue.use(PiniaVuePlugin);
        const pinia = createPinia();
        const SignupForm = (await import('./components/signup-multi-form/SignupDynamicForm.vue')).default;
        new Vue({
            el: '#signup-page-container',
            render: (h) => h(SignupForm),
            components: { SignupMultiForm: SignupForm },
            pinia,
        });
    }

    // Loads Pie cookie component if needed (just for France & Denmark for now)
    if (document.getElementById('pie_cookie_bar')) {
        let CookieBar = (await import('./components/CookieBar.vue')).default;
        new Vue({
            el: '#pie_cookie_bar',
            render: (h) => h(CookieBar),
            components: { CookieBar },
        });
    }
})();
// Simply query the JET FM A/A test and do nothing with the value.
// The experiment event will be tracked by the JET FM JavaScript SDK, using the Tealium hook.
// See feature-management.js for more details.
getAATestFeatureValue().then();
