document.addEventListener(
    'DOMContentLoaded',
    () => {
        const carouselContainers = document.querySelectorAll('.carousel, .carousel--arrows, .carousel--how');

        carouselContainers.forEach((carouselContainer) => {
            const isRtl = document.body.classList.contains('rtl');
            const carousel = carouselContainer.querySelector('.carousel__slides');
            const bullets = carouselContainer.querySelectorAll('.carousel__bullet');
            const prevArrow = carouselContainer.querySelectorAll('.carousel__arrow--prev');
            const nextArrow = carouselContainer.querySelectorAll('.carousel__arrow--next');
            const currentNumber = carouselContainer.querySelector('.carousel__numbers__current');

            let relatedCarousel = null;
            if (carouselContainer.getAttribute('data-depends')) {
                relatedCarousel = document.querySelector(carouselContainer.getAttribute('data-depends'));
            }

            const changeSlide = (n, isIndex = false) => {
                const activeSlide = carousel.querySelector('.carousel__slide.is-active');
                const slides = carousel.querySelectorAll('.carousel__slide');
                const index = [].indexOf.call(slides, activeSlide);

                let relatedSlides = null;
                if (relatedCarousel) {
                    relatedSlides = relatedCarousel.querySelectorAll('.carousel__slide');
                }

                if (prevArrow && nextArrow) {
                    prevArrow.forEach(function (el) {
                        el.classList.remove('is-active');
                    });
                    nextArrow.forEach(function (el) {
                        el.classList.remove('is-active');
                    });
                }

                slides.forEach((slide, idx) => {
                    slide.classList.remove('is-active');

                    if (relatedCarousel && relatedSlides.length) {
                        relatedSlides[idx].classList.remove('is-active');
                    }
                    if (bullets[idx]) {
                        bullets[idx].classList.remove('is-active');
                    }

                    if (prevArrow && nextArrow) {
                        prevArrow.forEach(function (el) {
                            el.classList.remove('is-active');
                        });
                        nextArrow.forEach(function (el) {
                            el.classList.remove('is-active');
                        });
                    }
                    if (isIndex) {
                        const dirTranslate = isRtl ? 100 : -100;
                        const translate = Math.min(Math.max(n, 0), slides.length - 1) * dirTranslate;
                        slide.style.transform = `translateX(${translate}%)`;
                        if (relatedCarousel && relatedSlides.length) {
                            relatedSlides[idx].style.transform = `translateX(${translate}%)`;
                        }
                        if (idx === n) {
                            slide.classList.add('is-active');
                            if (relatedCarousel && relatedSlides.length) {
                                relatedSlides[idx].classList.add('is-active');
                            }
                            if (bullets[n]) {
                                bullets[n].classList.add('is-active');
                            }
                            if (n > 0 && prevArrow) {
                                prevArrow.forEach(function (el) {
                                    el.classList.add('is-active');
                                });
                            }
                            if (n < slides.length - 1 && nextArrow) {
                                nextArrow.forEach(function (el) {
                                    el.classList.add('is-active');
                                });
                            }
                        }
                        if (prevArrow && n > 0) {
                            prevArrow.forEach(function (el) {
                                el.classList.add('is-active');
                            });
                        }
                        if (nextArrow && n < slides.length - 1) {
                            nextArrow.forEach(function (el) {
                                el.classList.add('is-active');
                            });
                        }
                    } else {
                        if (index > 0 || index < slides.length - 1) {
                            const dirTranslate = isRtl ? 100 : -100;
                            const translate = Math.min(Math.max(index + n, 0), slides.length - 1) * dirTranslate;
                            slide.style.transform = `translateX(${translate}%)`;
                            if (relatedCarousel && relatedSlides.length) {
                                relatedSlides[idx].style.transform = `translateX(${translate}%)`;
                            }
                        }

                        if (
                            idx === index + n ||
                            (idx === 0 && index + n < 0) ||
                            (idx === slides.length - 1 && index + n > slides.length - 1)
                        ) {
                            slide.classList.add('is-active');
                            if (relatedCarousel && relatedSlides.length) {
                                relatedSlides[idx].classList.add('is-active');
                            }
                            if (bullets[idx]) {
                                bullets[idx].classList.add('is-active');
                            }
                        }
                        if (prevArrow && index + n > 0) {
                            prevArrow.forEach(function (el) {
                                el.classList.add('is-active');
                            });
                        }
                        if (nextArrow && index + n < slides.length - 1) {
                            nextArrow.forEach(function (el) {
                                el.classList.add('is-active');
                            });
                        }
                    }
                });

                const currentActiveSlide = carousel.querySelector('.carousel__slide.is-active');
                const currentActiveIndex = [].indexOf.call(slides, currentActiveSlide);

                if (currentNumber) {
                    currentNumber.innerHTML = currentActiveIndex + 1;
                }
            };

            const addTouchEventsListener = (carousel) => {
                let startX;
                let startY;
                let distX;
                let distY;

                carousel.addEventListener(
                    'touchstart',
                    (e) => {
                        let touchElement = e.changedTouches[0];
                        distX = 0;
                        distY = 0;
                        startX = touchElement.pageX;
                        startY = touchElement.pageY;
                    },
                    { passive: true },
                );

                carousel.addEventListener(
                    'touchend',
                    (e) => {
                        let touchElement = e.changedTouches[0];

                        distX = touchElement.pageX - startX;
                        distY = touchElement.pageY - startY;

                        if (distX >= 70 && Math.abs(distY) <= 70) {
                            const direction = isRtl ? 1 : -1;
                            changeSlide(direction);
                        }

                        if (distX <= -70 && Math.abs(distY) <= 70) {
                            const direction = isRtl ? -1 : 1;
                            changeSlide(direction);
                        }
                    },
                    { passive: true },
                );

                // Drag and drop + Touchpad functionality
                let isSwipeInProgress = false;

                const addMouseDragEvents = (carousel) => {
                    let isDragging = false;
                    let startX = 0;
                    let currentX = 0;
                    let hasChangedSlide = false;

                    carousel.addEventListener('mousedown', (e) => {
                        isDragging = true;
                        hasChangedSlide = false;
                        startX = e.pageX;
                        document.body.style.userSelect = 'none'; // Prevent text selection
                    });

                    carousel.addEventListener('mousemove', (e) => {
                        if (!isDragging || hasChangedSlide || isSwipeInProgress) return;

                        currentX = e.pageX;
                        const distX = currentX - startX;

                        if (Math.abs(distX) > 70) {
                            const direction = distX > 0 ? -1 : 1;
                            isSwipeInProgress = true; // Prevent multiple swipes
                            changeSlide(direction);
                            hasChangedSlide = true;

                            // Reset after a small delay to allow for swipe to finish
                            setTimeout(() => {
                                isSwipeInProgress = false;
                            }, 300);
                        }
                    });

                    carousel.addEventListener('mouseup', () => {
                        isDragging = false;
                        document.body.style.userSelect = '';
                    });

                    carousel.addEventListener('mouseleave', () => {
                        isDragging = false;
                        document.body.style.userSelect = '';
                    });

                    carousel.addEventListener('wheel', (e) => {
                        if (Math.abs(e.deltaX) > 30 && !isSwipeInProgress) {
                            // Reverse direction
                            const direction = e.deltaX < 0 ? -1 : 1;
                            isSwipeInProgress = true; // Prevent multiple swipes
                            changeSlide(direction);

                            setTimeout(() => {
                                isSwipeInProgress = false;
                            }, 300);
                        }
                    });
                };

                addMouseDragEvents(carousel);
                if (relatedCarousel) {
                    addMouseDragEvents(relatedCarousel);
                }
            };

            addTouchEventsListener(carousel, changeSlide);

            if (relatedCarousel) {
                addTouchEventsListener(relatedCarousel, changeSlide);
            }

            if (prevArrow && nextArrow) {
                prevArrow.forEach(function (el) {
                    el.addEventListener(
                        'click',
                        (e) => {
                            // const direction = isRtl ? 1 : -1;
                            // changeSlide(direction);
                            changeSlide(-1);
                            e.preventDefault();
                        },
                        false,
                    );
                });
                nextArrow.forEach(function (el) {
                    el.addEventListener(
                        'click',
                        (e) => {
                            // const direction = isRtl ? -1 : 1;
                            // changeSlide(direction);
                            changeSlide(1);
                            e.preventDefault();
                        },
                        false,
                    );
                });
            }

            bullets.forEach((bullet) => {
                bullet.addEventListener(
                    'click',
                    (e) => {
                        const idx = [].indexOf.call(bullets, e.target);
                        changeSlide(idx, true);
                        e.preventDefault();
                    },
                    false,
                );
            });
        });
    },
    false,
);
