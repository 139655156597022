document.addEventListener('DOMContentLoaded', () => {
    const collapseButton = document.getElementById('cross_icon');
    const collapseContent = document.getElementById('seo_accordion--content');

    if (collapseButton) {
        collapseButton.addEventListener('click', () => {
            if (collapseContent.classList.contains('is-active')) {
                collapseContent.classList.remove('is-active');
                collapseButton.classList.remove('is-active');
            } else {
                collapseContent.classList.add('is-active');
                collapseButton.classList.add('is-active');
            }
        });
    }
});
