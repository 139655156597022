import TrackingScript from './tracking-script';
import { getActivatedExperiments } from '../feature-management';
import { cookiesAccepted, getCookie } from '../cookies';

/**
 * Implements Tealium tracking for events on the drivers page
 * @see https://docs.google.com/spreadsheets/d/1onyeR0JJCU1qeDvnJFq539X_b63WL0qD4FBc5jKraIQ
 */
export default class Tealium extends TrackingScript {
    sendEvents = false;
    preloadQueue = [];
    preCookieAcceptQueue = [];

    constructor() {
        super();
        if (!window.tealiumTrackingEnabled) {
            return;
        }

        this.needsCookies = false;
        this.inject();

        window.addEventListener('tmsControllerLoaded', () => {
            // double check the tmsController is really loaded
            if (window && window.tmsController && window.utag) {
                // wait a second because the Tealium tag has parallel threading issues
                setTimeout(() => {
                    if (process.env.NODE_ENV !== 'production') {
                        console.log('Tealium tmsController loaded'); // eslint-disable-line no-console
                    }
                    this.postInit();
                }, 1000);
            } else {
                if (process.env.NODE_ENV !== 'production') {
                    console.log('Tealium falsely reported loaded'); // eslint-disable-line no-console
                }
            }
        });

        // Interval to send events tracked before cookie consent
        let eventInterval = setInterval(() => {
            if (getCookie('cookieConsentCourier') !== null) {
                this.setupPageData();
                clearInterval(eventInterval);
                if (this.preCookieAcceptQueue.length) {
                    while (this.preCookieAcceptQueue.length) {
                        const next = this.preCookieAcceptQueue.shift();
                        this.trackEvent(next);
                    }
                }
            }
        }, 2000);
    }

    /**
     * Inject Tealium utag
     * @see: https://docs.tealium.com/platforms/javascript/install/#universal-tag-utag-js
     */
    async inject() {
        if (!(await this.allowedInject())) {
            return;
        }
        if (document.getElementById('script-tealium')) {
            return false;
        }

        let tag = document.createElement('script');
        tag.id = 'script-tealium';
        if (!window.tealiumEnvironment) {
            window.tealiumEnvironment = 'prod';
        }
        tag.src = '//tags.tiqcdn.com/utag/takeaway/recruitment-drivers/' + window.tealiumEnvironment + '/utag.js';
        tag.type = 'text/javascript';
        tag.async = false;
        tag.onerror = function () {};
        document.body.appendChild(tag);
    }

    /**
     * Runs all tracking functions that were called before Tealium was loaded
     */
    async postInit() {
        await this.allowedInject();

        this.sendEvents = true;

        while (this.preloadQueue.length) {
            const next = this.preloadQueue.shift();
            this.trackEvent(next);
        }
    }

    /**
     * Runs tracking events when the tealium tag has successfully loaded and catches errors which are unfortunately very common.
     * @param {function} eventFunction The tracking event function defined in this class
     */
    trackEvent(eventFunction) {
        if (this.sendEvents) {
            //check if cookie has been before tracking anything
            if (getCookie('cookieConsentCourier') === null) {
                this.preCookieAcceptQueue.push(eventFunction);
                return;
            }
            try {
                eventFunction();
            } catch (err) {
                if (process.env.NODE_ENV !== 'production') {
                    console.log('tracking event error:', err.message); // eslint-disable-line no-console
                }
            }
        } else {
            this.preloadQueue.push(eventFunction);
        }
    }

    setupPageData() {
        window.tmsController.data.pageData = {
            url: window.location.pathname,
            country: window.country.code,
            language: window.language.code,
            pageGroup: window.pageGroup,
            experiment_details: getActivatedExperiments(),
        };

        if (window.pageGroup !== 'courier_hub') {
            window.tmsController.data.pageData['city'] = this.getCityValue();
        }

        this.addUtmData(false);
    }

    setPageGroup(pageGroup) {
        window.pageGroup = pageGroup;
        if (window.tmsController) {
            window.tmsController.data.pageData.pageGroup = pageGroup;
        }
    }

    /**
     * Tracks the current pageview
     */
    trackPageview() {
        this.trackEvent(() => {
            window.tmsController.data.pageData = {
                url: window.location.pathname,
                country: window.country.code,
                language: window.language.code,
                city: window.tmsController.data.pageData.city,
                pageGroup: window.pageGroup,
                experiment_details: window.tmsController.data.pageData.experiment_details,
            };

            this.addUtmData(false);

            window.tmsController?.trackPageview();
        });
    }

    /**
     * This event is triggered when a user has given consent.
     */
    hasGivenCookieConsent() {
        this.trackEvent(() => {
            this.addUtmData(true);
            window.tmsController?.trackEvent('hasGivenCookieConsent');
        });
    }

    /**
     * This event is triggered when the cookie banner is viewed.
     */
    hasViewedCookieBanner() {
        this.trackEvent(() => {
            // This event is usually called directly after document load and therefore after trackPageView. The tracking tag has a bug that causes a
            // race condition on the single data object that is used internally. We have to defer this event by at least 100ms to avoid this.
            setTimeout(() => {
                this.addUtmData(true);
                window.tmsController?.trackEvent('hasViewedCookieBanner');
            }, 1000);
        });
    }

    /**
     * This event is triggered when the user clicks on the extra cookie information link.
     */
    hasViewedExtraCookieInfo() {
        this.trackEvent(() => {
            this.addUtmData(true);
            window.tmsController?.trackEvent('hasViewedExtraCookieInfo');
        });
    }

    /**
     * This event is triggered when the user clicks on Apply now and no errors are triggered.
     * If different positions are possible, the vacancy should be sent along with it.
     */
    hasClickedApply(city, applicantId, application, hashed_email, hashed_phone) {
        this.trackEvent(() => {
            window.tmsController.data.eventData.applicationCity = city;
            window.tmsController.data.eventData.applicantId = applicantId;
            window.tmsController.data.eventData.application = application;
            window.tmsController.data.eventData.interactive_map = this.interactiveMapValue();
            window.tmsController.data.eventData.hashed_email = hashed_email;
            window.tmsController.data.eventData.hashed_phone = hashed_phone;
            this.addUtmData(true);
            window.tmsController?.trackEvent('hasClickedApply');
        });
    }

    /**
     * Clicked 'next' on testimonial carousel
     */
    hasClickedNextTestimonial() {
        this.trackEvent(() => {
            this.addUtmData(true);
            window.tmsController?.trackEvent('hasClickedNextTestimonial');
        });
    }

    /**
     * Clicked 'previous' on testimonial carousel
     */
    hasClickedPreviousTestimonial() {
        this.trackEvent(() => {
            this.addUtmData(true);
            window.tmsController?.trackEvent('hasClickedPreviousTestimonial');
        });
    }

    /**
     * Clicked FAQ question: [Question]
     */
    hasClickedFAQQuestion(question) {
        this.trackEvent(() => {
            window.tmsController.data.eventData.faqQuestion = question;
            this.addUtmData(true);
            window.tmsController?.trackEvent('hasOpenedFaqModal');
        });
    }

    /**
     * Closed FAQ modal
     */
    hasClosedFAQModal(question) {
        this.trackEvent(() => {
            window.tmsController.data.eventData.faqQuestion = question;
            this.addUtmData(true);
            window.tmsController?.trackEvent('hasClosedFaqModal');
        });
    }

    /**
     * Changed language
     */
    hasChangedLanguage() {
        this.trackEvent(() => {
            this.addUtmData(true);
            window.tmsController?.trackEvent('hasChangedLanguage');
        });
    }

    /**
     * Changed country
     */
    hasChangedCountry() {
        this.trackEvent(() => {
            this.addUtmData(true);
            window.tmsController?.trackEvent('hasChangedCountry');
        });
    }

    cta_apply_form() {
        this.trackEvent(() => {
            window.tmsController.data.eventData = {
                component_name: 'cta',
                component_type: 'cta',
                component_action: 'apply form',
                url: window.location.pathname,
                country: window.country.code,
                language: window.language.code,
                pageGroup: window.pageGroup,
            };
            this.addUtmData(true);
            window.tmsController?.trackEvent('cta_apply_form');
        });
    }

    /**
     * Clicked footer link
     */
    hasCLickedFooterLink() {
        this.trackEvent(() => {
            this.addUtmData(true);
            window.tmsController?.trackEvent('hasClickedFooterLink');
        });
    }

    /**
     * closed notification banner
     */
    hasClosedNotificationBanner() {
        this.trackEvent(() => {
            this.addUtmData(true);
            window.tmsController?.trackEvent('hasClosedNotificationBanner');
        });
    }

    /**
     * User viewed form field/  Viewed [field type]: [field input name]
     */
    hasViewedFormField(name) {
        this.trackEvent(() => {
            window.tmsController.data.eventData.fieldName = name;
            this.addUtmData(true);
            window.tmsController?.trackEvent('hasViewedFieldInForm');
        });
    }

    /**
     * User filled form field/  filled [field type]: [field input name]
     */
    hasFilledFormField(name) {
        this.trackEvent(() => {
            window.tmsController.data.eventData.fieldName = name;
            this.addUtmData(true);
            window.tmsController?.trackEvent('hasModifiedFieldInForm');
        });
    }

    /**
     * User selected city on form
     */
    hasSelectedCity(city) {
        this.trackEvent(() => {
            window.tmsController.data.eventData.applicationCity = city;
            this.addUtmData(true);
            window.tmsController?.trackEvent('hasSelectedApplicationCity');
        });
    }

    /**
     * This event is triggered when something is wrong with the form input data
     * @param {*} component_name
     * @param {*} error_message
     * @param {*} form_info
     */
    formValidationError(component_name, error_message, form_info) {
        this.trackEvent(() => {
            window.tmsController.data.eventData = {
                component_type: 'form',
                component_name: component_name,
                component_action: 'error',
                error_message: error_message,
            };

            this.addUtmData(true);
            window.tmsController.trackEvent('formField_error');

            window.tmsController.data.eventData = {
                component_type: 'form',
                component_name: form_info,
                component_action: 'error',
            };

            this.addUtmData(true);
            window.tmsController?.trackEvent('form_error');
        });
    }

    /**
     * Is called when the applicant if rejected on form submission
     */
    hasApplicantRejectedOnFormSubmission(fieldInputName = 'unknown') {
        this.trackEvent(() => {
            window.tmsController.data.eventData.fieldName = fieldInputName;
            this.addUtmData(true);
            window.tmsController?.trackEvent('hasApplicantRejectedOnFormSubmission');
        });
    }

    /**
     * This event is triggered when a user clicks on calculate salary
     */
    hasClickedCalculateSalary() {
        this.trackEvent(() => {
            this.addUtmData(true);
            window.tmsController?.trackEvent('hasClickedCalculateSalary');
        });
    }

    /**
     * This event is triggered when a user clicks the icons on the map
     * @param {'hub' | 'house' | 'remote'} iconName
     */
    hasClickedMapIcon(iconName) {
        this.trackEvent(() => {
            window.tmsController.data.eventData = {
                component_name: 'interactiveMap',
                component_type: 'map',
                component_action: 'click',
                url: window.location.pathname,
                country: window.country.code,
                language: window.language.code,
                icon: iconName,
            };
            this.addUtmData(true);
            window.tmsController?.trackEvent('map_click');
        });
    }

    /**
     * This event is triggered when a user search a new city on the map
     * @param {string} cityName
     */
    hasSearchedCity(cityName) {
        this.trackEvent(() => {
            window.tmsController.data.eventData = {
                component_name: 'interactiveMap',
                component_type: 'searchBox',
                component_action: 'search',
                url: window.location.pathname,
                country: window.language.code,
                city: cityName,
            };
            this.addUtmData(true);
            window.tmsController?.trackEvent('interactiveMap_search');
        });
    }

    /***
     * This event is triggered when the user interacts with the map because of zoom in out
     */
    hasZoomedMap(zoomedIn = true) {
        this.trackEvent(() => {
            window.tmsController.data.eventData = {
                component_name: 'interactiveMap',
                component_type: 'map',
                component_action: zoomedIn ? 'zoom_in' : 'zoom_out',
                pageGroup: window.pageGroup,
            };
        });
        window.tmsController?.trackEvent('map_interaction');
    }

    trackJetfmExperiment(experimentId, variant) {
        this.trackEvent(() => {
            window.tmsController.data.eventData = {
                experimentId: experimentId,
                variant: variant,
            };
            this.addUtmData(true);
            window.tmsController?.trackEvent('jetfm_experiment');
        });
    }

    /**
     * This event is triggered when the user access a category page
     * @param {*} categoryName
     */
    hasLoadedCategoryPage(categoryName) {
        this.trackEvent(() => {
            window.tmsController.data.eventData = {
                component_type: 'category',
                component_action: 'load',
                category_name: categoryName,
            };
            this.addUtmData(true);
            window.tmsController?.trackEvent('general_category');
        });
    }

    /**
     * This event is triggered when the user has accessed an article page
     */
    hasLoadedArticlePage(categoryName, articleName) {
        this.trackEvent(() => {
            window.tmsController.data.eventData = {
                component_type: 'article',
                component_action: 'load',
                category_name: categoryName,
                article_name: articleName,
            };
            this.addUtmData(true);
            window.tmsController?.trackEvent('article');
        });
    }

    /**
     * This event is triggered when the user scrolls @param {percentage} of the @param {article} article
     * At this moment we only track if the user scrolls 50% and/or 90%
     */
    hasScrolledArticlePage(percentage, articleName) {
        this.trackEvent(() => {
            window.tmsController.data.eventData = {
                component_type: 'scroll',
                article_name: articleName,
                scroll_action: percentage + '%',
            };
            this.addUtmData(true);
            window.tmsController?.trackEvent('scroll');
        });
    }

    /**
     * This event is triggered when the user scrolls @param {percentage} of the page
     */
    hasScrolledCountryPage = (percentage) => {
        this.trackEvent(() => {
            window.tmsController.data.eventData = {
                component_name: 'recruitment page',
                component_type: 'scroll',
                scroll_action: percentage + '%',
                url: window.location.pathname,
                country: window.language.code,
                language: window.language.code,
                pageGroup: window.pageGroup,
            };
            this.addUtmData(true);
            window.tmsController?.trackEvent('scroll');
        });
    };

    /**
     * This event is triggered when the user clicks the CTA button Apply Now on the article page
     */
    hasClickedCTAButton() {
        this.trackEvent(() => {
            window.tmsController.data.eventData = {
                component_name: window.location.href,
                component_type: 'cta',
                component_action: 'cta_apply',
                url: window.location.pathname,
                country: window.country.code,
                language: window.language.code,
                pageGroup: window.pageGroup,
            };
            this.addUtmData(true);
            window.tmsController?.trackEvent('cta_apply');
        });
    }

    /**
     * This event is triggered when the user loads a subcategory page of the Help Center category
     */
    hasLoadedHelpCenterSubcategoryPage(subcategoryName) {
        this.trackEvent(() => {
            window.tmsController.data.eventData = {
                component_name: 'general_category',
                component_type: 'help_center',
                component_action: 'load',
                category_name: subcategoryName,
            };
            this.addUtmData(true);
            window.tmsController?.trackEvent('help_center');
        });
    }

    /**
     * This event is triggered when the user opens a FAQ item in courier hub
     */
    hasOpenedFAQItemCourierHub(faqItemName) {
        this.trackEvent(() => {
            window.tmsController.data.eventData = {
                component_name: faqItemName,
                component_type: 'faq',
                component_action: 'open',
            };
            this.addUtmData(true);
            window.tmsController?.trackEvent('faq_open');
        });
    }

    /**
     * This event is triggered when the user closes a FAQ item in courier hub
     */
    hasClosedFAQItemCourierHub(faqItemName) {
        this.trackEvent(() => {
            window.tmsController.data.eventData = {
                component_name: faqItemName,
                component_type: 'faq',
                component_action: 'close',
            };
            this.addUtmData(true);
            window.tmsController?.trackEvent('faq_close');
        });
    }

    /**
     * This event is triggered when a user clicks on the UEFA banner button
     */
    hasClickedUEFAButton() {
        this.trackEvent(() => {
            window.tmsController.data.eventData = {
                component_name: window.location.href,
                component_type: 'cta',
                component_action: 'cta_uefa',
                url: window.location.pathname,
                country: window.country.code,
                language: window.language.code,
                pageGroup: window.pageGroup,
            };

            this.addUtmData(true);

            window.tmsController?.trackEvent('cta');
        });
    }

    /**
     * This event is triggered when a user interacts with the salary slider
     */
    hasInteractedWithSalarySlider(field, city_slider, hours_slider, age_slider) {
        this.trackEvent(() => {
            window.tmsController.data.eventData = {
                component_type: 'salary_slider',
                component_name: field,
                component_action: 'edit',
                city_slider: city_slider ?? 'not selected',
                hours_slider: hours_slider,
                age_slider: age_slider,
            };

            this.addUtmData(true);

            window.tmsController?.trackEvent('slider_interaction');
        });
    }

    /**
     * This event is triggering when the user clicks on the button to go forward in the form and there are no errors in any field.
     */
    hasClickedCtaProceed() {
        this.trackEvent(() => {
            window.tmsController.data.eventData = {
                component_name: 'cta',
                component_type: 'cta',
                component_action: 'proceed',
                url: window.location.pathname,
                country: window.country.code,
                language: window.language.code,
                pageGroup: window.pageGroup,
                interactive_map: this.interactiveMapValue(),
            };
            this.addUtmData(true);
            window.tmsController?.trackEvent('cta_proceed');
        });
    }

    /**
     * This event is triggered when the user submits the form
     * @param {'successfull' | 'rejected' | 'network_error'} status
     * @param {string} applicantId
     * @param {string} applicationCity
     * @param {string} hashed_email
     * @param {string} hashed_phone
     */
    hasSubmittedForm(status, applicantId, applicationCity, hashed_email, hashed_phone) {
        this.trackEvent(() => {
            window.tmsController.data.eventData = {
                component_name: 'application_status',
                component_type: 'application',
                component_action: status,
                url: window.location.pathname,
                country: window.country.code,
                application_city: applicationCity,
                language: window.language.code,
                pageGroup: window.pageGroup,
                applicantId: applicantId,
                interactive_map: this.interactiveMapValue(),
                hashed_email: hashed_email,
                hashed_phone: hashed_phone,
            };
            this.addUtmData(true);
            window.tmsController?.trackEvent('application_status');
        });
    }

    /**
     * This event is triggered when a connection / server error occurs
     * @param {'-'} componentName
     * @param {'-'} errorMoment
     * @param {string} errorMessage
     */
    hasNetworkError(errorStatus, errorMessage, form_info) {
        this.trackEvent(() => {
            window.tmsController.data.eventData = {
                component_type: 'form',
                component_action: 'error',
                component_name: form_info,
                error_code: errorStatus,
                error_message: errorMessage,
            };
            this.addUtmData(true);
            window.tmsController?.trackEvent('api_error');

            window.tmsController.data.eventData = {
                component_type: 'form',
                component_name: form_info,
                component_action: 'error',
            };

            this.addUtmData(true);
            window.tmsController?.trackEvent('form_error');
        });
    }

    /**
     * Helper function for adding UTM data to page/event data
     * @param {boolean} isEvent
     */
    addUtmData(isEvent) {
        if (cookiesAccepted('analytical')) {
            if (isEvent) {
                // Add campaign to event data
                if (window.tmsController.data.pageData.page_category) {
                    window.tmsController.data.eventData.page_category =
                        window.tmsController.data.pageData.page_category;
                }

                // Add city to event data
                if (window.tmsController.data.pageData.city) {
                    window.tmsController.data.eventData.city = window.tmsController.data.pageData.city;
                }
            } else {
                // Add campaign to event data
                if (window.utm_campaign) {
                    window.tmsController.data.pageData.page_category = window.utm_campaign;
                }

                // Add city to event data
                if (window.utm_term) {
                    window.tmsController.data.pageData.city = window.utm_term;
                }
            }
        }
    }

    trackMapComplete(callback) {
        this.cancelMapCompleteTimeoutIfNeeded();
        window.trackingMapCompleteTimeout = setTimeout(() => {
            window.trackingMapComplete = callback();
        }, 500);
    }

    cancelMapCompleteTimeoutIfNeeded() {
        if (window.trackingMapCompleteTimeout) {
            clearTimeout(window.trackingMapCompleteTimeout);
        }
    }

    interactiveMapValue() {
        if (window.trackingMapComplete === undefined) {
            return undefined;
        }

        return window.trackingMapComplete ? 'complete' : 'incomplete';
    }
}
